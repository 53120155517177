// Here you can add other styles

.map {
  border: 0px solid $gray-500; 
  position: absolute;
  width: calc(100% - 0px);
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.result-slider {
  width: 30vh
}

.map-overlay {
  background-color: rgba(255, 255, 255, .9);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  position: absolute;
  width: 200px;
  height: 400px;
  top: 20px;
  left: 20px;
  padding: 0px;
  z-index: 100;
}

.map-overlay .inner {
  padding: 10px;
}

.map-overlay .persist {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.map-overlay .slider {
  width: 100%;
}

.map-overlay Label {
  margin: 0px;
}

.map-overlay hr {
 margin: 5px;
}

.map-overlay-selected {
  background-color: rgba(255, 255, 255, 0.849);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  position: absolute;
  top: 130px;
  left: 435px;
  padding: 0px;
  z-index: 100;
}

.map-overlay-selected .inner {
  width: 200px;
  height: 300px;
  padding: 10px;
}


.map-overlay-selected .inner hr {
  margin: 2px;
  padding: 2px;
  margin-top: 6px;
}

.map-overlay-selected Label {
  margin: 0px;
}

.map-overlay-selected .guid {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.map-overlay-selected .guid hr {
  margin: 1px;
  padding: 1px;
}

.map-overlay-selected .guid Label {
  font-size: 9.25px;
}

.map-overlay-armarker {
  position: absolute;
  padding: 0px;
  z-index: 100;
}